import React, { Component } from 'react';
import { Layout } from 'antd';
import { Switch, Route} from "react-router-dom";

import AppHeader from '../AppHeader/AppHeader';
import AppFooter from '../AppFooter/AppFooter';
import About from '../Pages/About/About';
import Home from '../Pages/Home/Home';
import AllCountry from '../Pages/AllCountry/AllCountry';
import TestCenter from '../Pages/TestCenter/TestCenter';
import Helpline from '../Pages/Helpline/Helpline';

class Main extends Component {
    render () {
        return(
            <React.Fragment>
                <Layout className="bg-white">
                    <AppHeader />
                    <Switch>
                        <Route path="/" exact><Home /></Route>
                        <Route path="/test-center" exact><TestCenter /></Route>
                        <Route path="/helpline" exact><Helpline /></Route>
                        <Route path="/all-Country" exact><AllCountry /></Route>
                        <Route path="/about" exact><About /></Route>
                    </Switch>
                    <AppFooter />
                </Layout>
            </React.Fragment>
        );
    }
}

export default Main;