import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const appFooter = () => {
  return <Footer style={{ textAlign: 'center' }}>
    This is not an official Government of India site. Created by <a href="mailto:theatulrai@gmail.com">Atul Rai</a></Footer>
};

export default appFooter;