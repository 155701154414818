import React, { Component } from 'react';
import { Statistic, Skeleton } from 'antd';

class Counter extends Component {

    state = {
        loading: true,
    }

    render() {
        const { name, count, color, suffix } = this.props;
        let details = <Skeleton loading={this.state.loading} active />
        if(this.props.totalCases != null) {
            details = <Statistic title={name} value={count} valueStyle={{color}}
                        suffix={suffix}/>
        }
        return (
            <React.Fragment>
                {details}
            </React.Fragment>
        );
    }
}

export default Counter;