import React, { Component } from 'react';
import { Row, Col } from 'antd';

class About extends Component {
    render () {
        return (
            <Row justify="center" align="middle" style={{minHeight: '85vh'}}>
                <Col span={24} className="text-center">
                    <h2 className="heading">This is not an official Government of India site</h2>
                    <a href="mailto:theatulrai@gmail.com?Subject=Covid19%20suggestion | issue | reporting data">Report a Bug</a>
                    <p>Inspired by <a href="https: //co.vid19.sg/">Upcode</a></p>
                </Col>
            </Row>
        );
    }
}

export default About;