import React from 'react';
import { Menu, Row, Col, Affix } from 'antd';
import { Link } from "react-router-dom";

class AppHeader extends React.Component {
    state = {
        current: 'home',
    };

    handleClick = e => {
        this.setState({
            current: e.key,
        });
    };
    render() {
        return (
            <React.Fragment>
                <Affix offsetTop={0}>
                    <div className="aShadow border-0 p-0 mb-5 bg-white">
                        <div className="container">
                            <Row justify="left" align="middle">
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}span={8} className="text-center text-sm-left">
                                <h4 className="pt-3"><Link to="/">theatulrai</Link></h4>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} className="text-center text-sm-right">
                                <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} className="border-0" mode="horizontal">
                                    <Menu.Item key="home" className="border-0"> <Link to="/">Home</Link> </Menu.Item>
                                    <Menu.Item key="test-center" className="border-0"> <Link to="/test-center">Test Center</Link> </Menu.Item>
                                    <Menu.Item key="helpline" className="border-0"> <Link to="/helpline">Helpline</Link> </Menu.Item>
                                    <Menu.Item key="all-Country" className="border-0"> <Link to="/all-Country">All Country</Link> </Menu.Item>
                                    <Menu.Item key="about" className="border-0"> <Link to="/about">About</Link> </Menu.Item>
                                    {/* <Menu.Item key="contact"> Contact </Menu.Item> */}
                                </Menu>
                            </Col>
                        </Row>
                        </div>
                    </div>
                </Affix>
            </React.Fragment>
        )
    }
}

export default AppHeader;