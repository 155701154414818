import React, { Component } from 'react';
import { Row, Col, Select, Empty, Card, Statistic, Button, Spin } from 'antd';

const { Option } = Select;

class CountryWiseData extends Component {

    state = {
        selectedCountry: "",
        loading: true
    }
    onChange = (countryName) => {
        this.setState({selectedCountry: countryName});
        this.setState({loading: false});
    }

    render () {
        const {allData, loadingStatus } = this.props;
            let countryOption = <Select size="large" loading={loadingStatus} showSearch style={{ width: 300 }} placeholder="Search/Select a Country"
                                    optionFilterProp="children" onChange={this.onChange}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {allData.map(c => <Option value={c.country} key={c.country} >{c.country}</Option>)}
                                </Select>;
            let selectedCountryData = allData.filter(item => item.country === this.state.selectedCountry);
            let selectedCountryD;
            let tempData = selectedCountryData[0];

            if (tempData) {
                selectedCountryD =
                    <div className="pt-5 pb-2 pl-0 pr-0 container text-center text-sm-left" >
                        <Row justify="space-around" align="middle" className="mb-4">
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Button type="dashed" size="large" className="border-0 p-0">
                                    <Spin spinning={this.state.loading}>
                                        <h2 className="heading"> {tempData.country} </h2>
                                    </Spin>
                                </Button>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="text-center text-sm-right">
                                <Button type="primary" size="large" className="bRounded themeBg">
                                    <Spin spinning={this.state.loading}>
                                        Today Cases: {tempData.todayCases} ||
                                        Today Death: {tempData.todayDeaths}
                                    </Spin>
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={{ xs: 4, sm: 4, md: 8, lg: 8 }} justify="space-around" align="middle" className="mb-1">
                            <Col className="gutter-row mb-2" xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card className="rounded dataCard">
                                    <Statistic title="TOTAL CASES" value={tempData.cases} suffix=' total cases'/>
                                </Card>
                            </Col>
                            <Col className="gutter-row mb-2" xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card className="rounded dataCard">
                                    <Statistic title="CURRENTLY IN HOSPITAL" value={tempData.active} suffix=' of total cases'/>
                                </Card>

                            </Col>
                            <Col className="gutter-row mb-2" xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card className="rounded dataCard">
                                    <Statistic title="DISCHARGED CASES" value={tempData.recovered} suffix=' of total cases'/>
                                </Card>
                            </Col>
                            <Col className="gutter-row mb-2" xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card className="rounded dataCard">
                                    <Statistic title="DECEASED CASES" value={tempData.deaths} suffix=' of total cases'/>
                                </Card>
                            </Col>
                        </Row>
                    </div>;
            } else {
                selectedCountryD = <Empty className="mt-5" description={false} />;
            }
        return (
            <div>
                <Row>
                    <Col>
                        <h2 className="heading"> Get the current status by Country </h2>
                        {countryOption}
                        {selectedCountryD}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CountryWiseData;