import React, { Component } from 'react';
import { Descriptions, Skeleton, Row, Col } from 'antd';

class AllCountry extends Component {
    state = {
        allCountry: [],
        loading: true,
    }

    getAllCountryData = () => {
        fetch('https://coronavirus-19-api.herokuapp.com/countries')
            .then(response => response.json())
            .then(json => {
                this.setState({allCountry: json})
                this.setState({ loading: !this.state.loading });
            })
    }

    componentDidMount(){
        this.getAllCountryData();
    }

    render () {
        const allCountryData = this.state.allCountry.map(c =>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} key={c.country} className="mb-2">
                <Descriptions title={c.country} className="mb-2 p-0 rounded dataCard">
                    <Descriptions.Item label="Cases">{c.cases}</Descriptions.Item>
                    <Descriptions.Item label="Today Cases">{c.todayCases}</Descriptions.Item>
                    <Descriptions.Item label="Deaths">{c.deaths}</Descriptions.Item>
                    <Descriptions.Item label="TodayDeaths">{c.todayDeaths}</Descriptions.Item>
                    <Descriptions.Item label="Recovered">{c.recovered}</Descriptions.Item>
                    <Descriptions.Item label="Active">{c.active}</Descriptions.Item>
                </Descriptions>
            </Col>);
        return (
            <div className="container pt-5 pb-5 pl-2 pr-2 pl-sm-0 pr-sm-0" style={{minHeight: '100vh'}}>
                <h2 className="heading mb-4 text-center"> COVID-19 Virus Outbreak by Country </h2>
                <Skeleton loading={this.state.loading} active paragraph={{ rows: 6 }}>
                    <Row gutter={{ xs: 4, sm: 4, md: 8, lg: 8 }} justify="space-around" align="middle">
                        {allCountryData}
                    </Row>
                </Skeleton>
            </div>
        );
    }
}

export default AllCountry;