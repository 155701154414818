import React, { Component } from 'react';
import { Table } from 'antd';

class Helpline extends Component {

    render () {
        const columns = [
                {
                    title: 'Sr.No',
                    dataIndex: 'key',
                    key: 'key',
                },
                {
                    title: 'State',
                    dataIndex: 'state',
                    key: 'state',
                },
                {
                    title: 'Helpline Number',
                    dataIndex: 'helplineNumber',
                    key: 'helplineNumber',
                    render: text => {
                        let phone = `tel:${text}`;
                        return (<a href={phone}>{text}</a>);
                    }
                }
                ];
        const data = [{ key: '1.', state: 'Andhra Pradesh', helplineNumber: '08662410978'},
                      { key: '2.', state: 'Arunachal Pradesh', helplineNumber: '9536055743'},
                      { key: '3.', state: 'Assam', helplineNumber: '6913347770'},
                      { key: '4.', state: 'Bihar', helplineNumber: '104'},
                      { key: '5.', state: 'Chhattisgarh', helplineNumber: '07712235091'},
                      { key: '6.', state: 'Goa', helplineNumber: '104'},
                      { key: '7.', state: 'Gujarat', helplineNumber: '104'},
                      { key: '8.', state: 'Haryana', helplineNumber: '8558893911'},
                      { key: '9.', state: 'Himachal Pradesh', helplineNumber: '104'},
                      { key: '10.', state: 'Jharkhand', helplineNumber: '104'},
                      { key: '11.', state: 'Karnataka', helplineNumber: '104'},
                      { key: '12.', state: 'Kerala', helplineNumber: '04712552056'},
                      { key: '13.', state: 'Madhya Pradesh', helplineNumber: '07552527177'},
                      { key: '14.', state: 'Maharashtra	', helplineNumber: '02026127394'},
                      { key: '15.', state: 'Manipur', helplineNumber: '3852411668'},
                      { key: '16.', state: 'Meghalaya', helplineNumber: '9366090748'},
                      { key: '17.', state: '	Mizoram', helplineNumber: '102'},
                      { key: '18.', state: 'Nagaland', helplineNumber: '7005539653'},
                      { key: '19.', state: 'Odisha', helplineNumber: '9439994859'},
                      { key: '20.', state: 'Punjab', helplineNumber: '104'},
                      { key: '21.', state: 'Rajasthan', helplineNumber: '01412225624'},
                      { key: '22.', state: 'Sikkim', helplineNumber: '104'},
                      { key: '23.', state: 'Tamil Nadu', helplineNumber: '04429510500'},
                      { key: '24.', state: 'Telangana', helplineNumber: '104'},
                      { key: '25.', state: 'Tripura', helplineNumber: '03812315879'},
                      { key: '26.', state: 'Uttarakhand', helplineNumber: '104'},
                      { key: '27.', state: 'Uttar Pradesh', helplineNumber: '18001805145'},
                      { key: '28.', state: 'West Bengal', helplineNumber: '3323412600'},
        ];


        return (
            <div className="container pt-5 pb-5 pl-2 pr-2 pl-sm-0 pr-sm-0">
                <h2 className="heading text-center mb-4">Helpline </h2>
                <Table pagination={false} columns={columns} dataSource={data} size="small"/>
            </div>
        );
    }
}

export default Helpline;