import React, { Component } from 'react';
import { Row, Col, Card, Spin, Button } from 'antd';

import Counter from './Counter/Counter';
import CountryWiseData from './CountryWiseData/CountryWiseData';

class Home extends Component {

    state = {
        currentStatus: "",
        loading: true,
        allCountryData: [],
        allCountryLoading: true
    }

    indiaData = (countryName) => {
        fetch(`https://coronavirus-19-api.herokuapp.com/countries/${countryName}`)
            .then(response => response.json())
            .then(json => {
                this.setState({currentStatus: json})
                this.setState({loading: false})
            })
            .catch(err => {
                console.log(err);
            });
    }

    allCountry = () => {
        fetch("https://coronavirus-19-api.herokuapp.com/countries/")
            .then(response => response.json())
            .then(json => {
                this.setState({allCountryData: json})
                this.setState({allCountryLoading: false})
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.indiaData("india");
        this.allCountry();
    }

    render () {
        const { cases, todayCases, deaths, todayDeaths, recovered, active, critical } = this.state.currentStatus;
        let icu = critical + " in ICU";

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;

        return (
            <div className="container pt-0 pb-5 pl-2 pr-2 pl-sm-0 pr-sm-0 text-center text-sm-left">
                <Row justify="space-around" align="middle" className="mb-4 text-center" >
                    <Col span={24}>
                        <Button type="dashed" size="large" className="roundedBtn pl-4 pr-4">
                            <p> Date: <span>{today}</span> </p>
                        </Button>
                    </Col>
                </Row>
                <Row justify="space-around" align="middle" className="mb-4">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <h2 className="heading">COVID-19 Virus Outbreak in India </h2>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className="text-center text-sm-right">
                        <Button type="primary" size="large">
                            <Spin spinning={this.state.loading}>
                                Today Cases: {todayCases} ||
                                Today Death: {todayDeaths}
                            </Spin>
                        </Button>
                    </Col>
                </Row>
                <Row gutter={{ xs: 4, sm: 4, md: 8, lg: 8 }} justify="space-around" align="middle" className="mb-4">
                    <Col className="gutter-row mb-2" xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Card className="rounded dataCard">
                            <Counter name='TOTAL CASES' totalCases={cases} count={cases} suffix={icu} color="#000" />
                        </Card>
                    </Col>
                    <Col className="gutter-row mb-2" xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Card className="rounded dataCard">
                            <Counter name='CURRENTLY IN HOSPITAL' totalCases={cases} count={active} suffix=" of total cases" color="#000" />
                        </Card>
                    </Col>
                    <Col className="gutter-row mb-2" xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Card className="rounded dataCard">
                            <Counter name='DISCHARGED CASES' totalCases={cases} count={recovered} suffix=" of total cases" color="#000" />
                        </Card>
                    </Col>
                    <Col className="gutter-row mb-2" xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Card className="rounded dataCard">
                            <Counter name='DECEASED CASES' totalCases={cases} count={deaths} suffix=" of total cases" color="red" />
                        </Card>
                    </Col>
                </Row>
                <CountryWiseData onChange={(name) => this.onChange(name)}
                        loadingStatus={this.state.allCountryLoading}
                        allData={this.state.allCountryData}/>
            </div>
        );
    }
}

export default Home;