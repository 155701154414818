import React, { Component } from 'react';
import { List } from 'antd';

const data = [{
        title: 'Andhra Pradesh',
        description: [{hos: 'Sri Venkateswara Institute of Medical Sciences in Tirupati GMC'},
                      {hos: 'Anantapur Siddhartha Medical College'},
                      {hos: 'Vijayawada Ranagaraya Medical College, Kakinada'}]
    },
    {
        title: 'Andaman and Nicobar',
        description: [{hos: 'Regional Medical Research Centre, Port Blair'}]
    },
    {
        title: 'Assam',
        description: [{hos: 'Gauhati Medical College, Guwahati'},
                      {hos: 'Regional Medical Research Centre, Dibrugarh'},
                      {hos: 'Silchar Medical College, Silchar'},
                      {hos: 'Jorhat Medical College, Jorhat'}]
    },
    {
        title: 'Bihar',
        description: [{hos: ' Rajendra Memorial Research Institute of Medical Sciences, Patna'}]
    },
    {
        title: 'Chandigarh',
        description: [{hos: 'Post Graduate Institute of Medical Education & Research'}]
    },
    {
        title: 'Chhattisgarh',
        description: [{
            hos: 'AIIMS, Raipur'
        }]
    },
    {
        title: 'Delhi',
        description: [{
            hos: 'AIIMS'
        }]
    },
    {
        title: 'Gujarat',
        description: [{hos: 'BJ Medical College, Ahmedabad'},
                    {hos: 'M P Shah Government Medical College, Jamnagar '}]
    },
    {
        title: 'Haryana',
        description: [{ hos: 'Pt B D Sharma Post Graduate Institute of Medical Sciences, Rohtak' },
            {hos: 'BPS Government Medical College, Sonipat'},
            {hos: 'BPS Government Medical College, Sonipat'}]
    },
    {
        title: 'Himachal Pradesh',
        description: [{hos: ' Rajendra Memorial Research Institute of Medical Sciences, Patna' },
                    {hos: 'Dr Rajendra Prasad Government Medical College, Kangra, Tanda'}]
    },
    {
        title: 'Jammu-Kashmir',
        description: [{hos: 'Sher-I-Kashmir Institute of Medical Sciences, Srinagar' },
                    {hos: 'Government Medical College, Jammu'},
                    {hos: 'Government Medical College, Srinagar'}]
    },
    {
        title: 'Jharkhand',
        description: [{hos: 'MGM Medical College, Jamshedpur' }]
    },
    {
        title: 'Karnataka',
        description: [{hos: 'Bangalore Medical College & Research Institute, Bengaluru' },
                    {hos: 'National Institute of Virology Field Unit, Bengaluru'},
                    {hos: 'Mysore Medical College & Research Institute, Mysore'},
                    {hos: 'Hassan Institute of Medical Sciences, Hassan'},
                    {hos: 'Shimoga Institute of Medical Sciences, Shivamoga'}]
    },
    {
        title: 'Kerala',
        description: [{
                    hos: 'National Institute of Virology Field, Kerala Unit' },
                    {hos: 'Government Medical College, Thriuvananthapuram '},
                    {hos: 'Government Medical College, Kozhikhode'},
                    {hos: 'Government Medical College, Thrissur'}]
    },
    {
        title: 'Madhya Pradesh',
        description: [{hos: 'AIIMS, Bhopal' },
                    {hos: 'National Institute of Research in Tribal Health (NIRTH), Jabalpur'}]
    },
    {
        title: 'Maharashtra',
        description: [{hos: 'Indira Gandhi Government Medical College, Nagpur ' },
                    {hos: 'Kasturba Hospital for Infectious Diseases, Mumbai'},
                    {hos: 'NIV Mumbai Unit'}]
    },
    {
        title: 'Manipur',
        description: [{hos: 'JN Institute of Medical Sciences Hospital, Imphal' },
                    {hos: 'Regional Institute of Medical Science, Imphal'}]
    },
    {
        title: 'Meghalaya',
        description: [{hos: 'North Eastern Indira Gandhi Institute of Health and Medical Sciences, Shillong' }]
    },
    {
        title: 'Odisha',
        description: [{hos: 'Regional Medical Research Centre, Bhubaneswar' }]
    },
    {
        title: 'Puducherry',
        description: [{hos: 'Jawaharlal Institute of Postgraduate Medical Education & Research, Puducherry' }]
    },
    {
        title: 'Punjab',
        description: [{
                    hos: 'Government Medical College, Patiala' },
                    {hos: 'Government Medical College, Amritsar '}]
    },
    {
        title: 'Rajasthan',
        description: [{hos: 'Sawai Man Singh Hospital, Jaipur' },
                    {hos: 'Dr S N Medical College, Jodhpur'},
                    {hos: 'Jhalawar Medical College, Jhalawar'},
                    {hos: 'SP Medical College, Bikaner'},
                    {hos: 'RNT Medical college, Udaipur'}]
    },
    {
        title: 'Tamil Nadu',
        description: [{hos: 'King’s Institute of Preventive Medicine & Research, Chennai' },
                    {hos: 'Government Medical College, Theni'},
                    {hos: 'Tirunelveli Medical College, Tirunelveli'},
                    {hos: 'Government Medical College, Thiruvarur'}]
    },
    {
        title: 'Telangana',
        description: [{hos: 'Gandhi Medical College, Secunderabad' },
                    {hos: 'Osmania Medical college, Hyderabad'}]
    },
    {
        title: 'Tripura',
        description: [{hos: 'Government Medical College, Agartala ' }]
    },
    {
        title: 'Uttar Pradesh',
        description: [{hos: 'King George Medical University, Lucknow ' },
                    {hos: 'nstitute of Medical Sciences, Banaras Hindu University, Varanasi'},
                    {hos: 'Jawaharlal Nehru Medical College, Aligarh'}]
    },
    {
        title: 'Uttarakhand',
        description: [{hos: 'Government Medical College, Haldwani ' }]
    },
    {
        title: 'West Bengal',
        description: [{hos: 'National Institute of Cholera and Enteric Diseases, Kolkata'},
                    {hos: 'IPGMER, Kolkata'}]
    },

];

class TestCenter extends Component {
    render () {
        return (
            <div className="container pt-5 pb-5 pl-3 pr-3 pl-sm-0 pr-sm-0">
                <h2 className="heading text-center mb-3">TestCenter</h2>
                <List itemLayout="horizontal" dataSource={data}
                    renderItem={item => (
                    <List.Item>
                        <div>
                            <h4 style={{fontSize: '20px'}} className="mb-2"> {item.title}</h4>
                            {item.description.map((c, index) => <p key={index} className="w-100">{index+1}. {c.hos}</p>)}
                        </div>
                    </List.Item>
                    )}
                />
            </div>
        );
    }
}

export default TestCenter;